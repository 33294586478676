import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function ShareButton({ shareableLink, partyCode }) {
  const [copied, setCopied] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  if (!shareableLink || !isVisible) return null;

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className='fixed-share-button'>
      <div className="share-content">
        <button className="close-button" onClick={() => setIsVisible(false)}>
          ✕
        </button>
        <p className="party-code">Party Code: {partyCode}</p>
        <CopyToClipboard text={shareableLink} onCopy={handleCopy}>
          <button 
            className="copy-button"
          >
            {copied ? '✓ Copied!' : 'Copy Invite Link'}
          </button>
        </CopyToClipboard>
      </div>

      <style jsx>{`
        .fixed-share-button {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(to top, #ffffff 80%, transparent);
          z-index: 1000;
        }

        .share-content {
          position: relative;
          max-width: 480px;
          margin: 0 auto;
          padding: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .close-button {
          position: absolute;
          top: 20px;
          right: 20px;
          background: none;
          border: none;
          color: #666666;
          font-size: 20px;
          cursor: pointer;
          padding: 5px;
          line-height: 1;
        }

        .close-button:hover {
          color: #FF4040;
        }

        .party-code {
          color: #666666;
          margin-bottom: 12px;
          font-size: 16px;
        }

        .copy-button {
          width: 80%;
          padding: 15px 30px;
          font-size: 16px;
          background-color: transparent;
          color: #FF4040;
          border: 2px solid #FF4040;
          border-radius: 5px;
          cursor: pointer;
          transition: all 0.2s;
          font-weight: bold;
        }

        .copy-button:hover {
          background-color: #FF4040;
          color: white;
        }
      `}</style>
    </div>
  );
}

export default ShareButton; 