import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ShareButton from './ShareButton';

function SubmittedMovies() {
  const location = useLocation();
  const navigate = useNavigate();
  const { participantName, partyCode } = location.state || {};
  const [submissions, setSubmissions] = useState([]);
  const [watchProviders, setWatchProviders] = useState({});
  const [shareableLink, setShareableLink] = useState('');
  const apiKey = '761fc976d86696491aa70fe1e99e7b9b';

  useEffect(() => {
    if (partyCode) {
      const baseUrl = window.location.origin;
      const link = `${baseUrl}/link-invitation?partyCode=${partyCode}`;
      setShareableLink(link);
    }
  }, [partyCode]);

  // Function to fetch watch providers for a movie
  const fetchWatchProviders = async (movieId) => {
    try {
      const response = await axios.get(
        `https://api.themoviedb.org/3/movie/${movieId}/watch/providers`,
        {
          params: { api_key: apiKey }
        }
      );
      // Get US providers (or fall back to GB if US not available)
      const results = response.data.results;
      return results.US || results.GB || null;
    } catch (error) {
      console.error('Error fetching watch providers:', error);
      return null;
    }
  };

  useEffect(() => {
    if (!partyCode) return;

    // Listen for winner selection
    const unsubscribeWinner = onSnapshot(doc(db, 'parties', partyCode), (doc) => {
      const data = doc.data();
      if (data?.winner) {
        navigate('/winner', {
          state: {
            movie: data.winner.movie,
            submitter: data.winner.submitter,
            partyCode: partyCode,
            watchProviders: data.winner.watchProviders
          },
          replace: true
        });
      }
    });

    // Prevent going back
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', () => {
      window.history.pushState(null, null, window.location.pathname);
    });

    // Redirect if no party code or participant name
    if (!partyCode || !participantName) {
      navigate('/');
      return;
    }

    // Set up real-time listener for submissions
    const submissionsRef = collection(db, 'parties', partyCode, 'submissions');
    const unsubscribe = onSnapshot(submissionsRef, async (snapshot) => {
      const submissionsData = [];
      const providersData = {};
      
      for (const doc of snapshot.docs) {
        const data = doc.data();
        try {
          // Fetch movie details
          const movieResponse = await axios.get(
            `https://api.themoviedb.org/3/movie/${data.movieId}`,
            {
              params: { api_key: apiKey }
            }
          );
          
          // Fetch watch providers
          const providers = await fetchWatchProviders(data.movieId);
          providersData[data.movieId] = providers;
          
          submissionsData.push({
            name: data.name,
            movie: movieResponse.data
          });
        } catch (error) {
          console.error('Error fetching movie details:', error);
        }
      }
      
      setSubmissions(submissionsData);
      setWatchProviders(providersData);
    });

    return () => {
      unsubscribeWinner();
      unsubscribe();
      window.removeEventListener('popstate', () => {
        window.history.pushState(null, null, window.location.pathname);
      });
    };
  }, [partyCode, participantName, navigate]);

  // Helper function to render watch providers
  const renderWatchProviders = (movieId) => {
    const providers = watchProviders[movieId];
    if (!providers) return null;

    // Combine all providers and remove duplicates
    const allProviders = new Map();
    
    // Add providers from each category
    ['flatrate', 'rent', 'buy'].forEach(type => {
      if (providers[type]) {
        providers[type].forEach(provider => {
          // Use provider_id as key to avoid duplicates
          allProviders.set(provider.provider_id, provider);
        });
      }
    });

    if (allProviders.size === 0) return null;

    return (
      <div className="watch-providers">
        <div className="provider-section">
          <h4>Watch on:</h4>
          <div className="provider-logos">
            {Array.from(allProviders.values()).map(provider => (
              <img
                key={provider.provider_id}
                src={`https://image.tmdb.org/t/p/original${provider.logo_path}`}
                alt={provider.provider_name}
                title={provider.provider_name}
                className="provider-logo"
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="submitted-movies-container">
      <div className="header-section">
        <h1>Submitted Movies</h1>
        <p>View all submitted movies for this session</p>
      </div>
      
      <div className="submissions-list">
        {submissions.map((submission, index) => (
          <div key={index} className="submission-card">
            <div className="submission-content">
              <img 
                src={submission.movie.poster_path 
                  ? `https://image.tmdb.org/t/p/w200${submission.movie.poster_path}` 
                  : '/na.png'
                } 
                alt={submission.movie.title}
                className="movie-poster"
              />
              <div className="movie-info">
                <h3>
                  {submission.movie.title} ({submission.movie.release_date?.split('-')[0] || 'N/A'})
                </h3>
                <p className="submitter">Submitted by: {submission.name}</p>
                <p className="overview">{submission.movie.overview}</p>
              </div>
            </div>
            {renderWatchProviders(submission.movie.id)}
          </div>
        ))}
      </div>

      <ShareButton shareableLink={shareableLink} partyCode={partyCode} />

      <style jsx>{`
        .submitted-movies-container {
          padding: 20px;
          max-width: 1200px;
          margin: 0 auto;
        }

        .submissions-list {
          display: grid;
          gap: 20px;
          margin-top: 20px;
        }

        .submission-card {
          background: #ffffff;
          border-radius: 8px;
          padding: 15px;
          box-shadow: 0 2px 5px rgba(0,0,0,0.3);
          border: 1px solid #eeeeee;
        }

        .submission-content {
          display: flex;
          gap: 20px;
          margin-bottom: 15px;
        }

        .movie-poster {
          width: 130px;
          height: 195px;
          border-radius: 4px;
          object-fit: cover;
          box-shadow: 0 2px 5px rgba(0,0,0,0.3);
        }

        .movie-info {
          flex: 1;
          font-size: 18px;
        }

        .movie-info h3 {
          margin: 0 0 0px 0;
          color: #333333;
          font-size: 18px;
        }

        .submitter {
          color: #FF4040;
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 14px;
        }

        .overview {
          color: #666666;
          font-size: 12px;
          line-height: 1.4;
          margin-top: 0px;
        }

        .watch-providers {
          border-top: 1px solid #eeeeee;
          margin-top: 15px;
        }

        .provider-section h4 {
          color: #FF4040;
          margin: 5px 0;
          font-size: 0.9em;
        }

        .provider-logos {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          align-items: center;
        }

        .provider-logo {
          width: 30px;
          height: 30px;
          border-radius: 5px;
          object-fit: cover;
        }

        .provider-logo:hover {
          transform: scale(1.1);
          transition: transform 0.2s ease;
        }
      `}</style>
    </div>
  );
}

export default SubmittedMovies; 