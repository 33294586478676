// src/components/RoleSelection.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import ShareButton from './ShareButton';

function RoleSelection() {
  const location = useLocation();
  const navigate = useNavigate();
  const { participantName, partyCode } = location.state || {};
  const [shareableLink, setShareableLink] = useState('');

  useEffect(() => {
    const checkExistingSubmission = async () => {
      if (!participantName || !partyCode) return;

      try {
        const submissionsRef = collection(db, 'parties', partyCode, 'submissions');
        const q = query(submissionsRef, where('name', '==', participantName));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          navigate('/submitted-movies', {
            state: { participantName, partyCode },
            replace: true
          });
          return;
        }
      } catch (error) {
        console.error('Error checking existing submission:', error);
      }
    };

    checkExistingSubmission();
  }, [participantName, partyCode, navigate]);

  useEffect(() => {
    if (partyCode) {
      const baseUrl = window.location.origin;
      const link = `${baseUrl}/link-invitation?partyCode=${partyCode}`;
      setShareableLink(link);
    }
  }, [partyCode]);

  const handleRoleSelect = (role) => {
    if (!participantName || !partyCode) {
      navigate('/');
      return;
    }

    switch (role) {
      case 'participant':
        navigate('/participant', { state: { participantName, partyCode } });
        break;
      case 'judge':
        navigate('/judge', { state: { participantName, partyCode } });
        break;
      default:
        navigate('/');
    }
  };

  return (
    <div className="home-container">
      <div className="header-section">
        <h2>Select Your Role</h2>
        <p>Choose how you want to participate</p>
      </div>
      
      <div className="button-container">
        <button 
          onClick={() => handleRoleSelect('participant')}
          className="action-button"
        >
          Participant
        </button>
        <button 
          onClick={() => handleRoleSelect('judge')}
          className="action-button-secondary"
        >
          Judge
        </button>
      </div>

      <div className="instructions">
        <p>1. Choose 1 <strong> judge</strong> amongst your group</p>
        <p>2. Everyone else is a <strong>participant</strong></p>
      </div>

      <ShareButton shareableLink={shareableLink} partyCode={partyCode} />

      <style jsx>{`
        .instructions {
          margin-top: 30px;
          width: 90%;
          max-width: 600px;
          padding: 15px;
          border-radius: 10px;
          font-size: 14px;
          line-height: 1.6;
          text-align: center;
          border: 2px solid #333;
        }

        .instructions p {
          margin: 10px 0;
        }

        .instructions strong {
          color: #FF4040;
        }
      `}</style>
    </div>
  );
}

export default RoleSelection;
