import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, setDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { logAnalyticsEvent } from '../firebase';

function Judge() {
  const location = useLocation();
  const navigate = useNavigate();
  const { participantName, partyCode } = location.state || {};
  const [submissions, setSubmissions] = useState([]);
  const apiKey = '761fc976d86696491aa70fe1e99e7b9b';

  useEffect(() => {
    if (!partyCode || !participantName) {
      navigate('/');
      return;
    }

    // Set judge name in party document
    setDoc(doc(db, 'parties', partyCode), {
      judge_name: participantName
    }, { merge: true });

    const submissionsRef = collection(db, 'parties', partyCode, 'submissions');
    const unsubscribe = onSnapshot(submissionsRef, async (snapshot) => {
      const submissionsData = [];
      
      for (const doc of snapshot.docs) {
        const data = doc.data();
        try {
          const movieResponse = await axios.get(
            `https://api.themoviedb.org/3/movie/${data.movieId}`,
            {
              params: { api_key: apiKey }
            }
          );
          
          submissionsData.push({
            movie: movieResponse.data,
            name: data.name
          });
        } catch (error) {
          console.error('Error fetching movie details:', error);
        }
      }
      
      setSubmissions(submissionsData);
    });

    return () => unsubscribe();
  }, [partyCode, participantName, navigate]);

  const handleSelectMovie = async (submission) => {
    try {
      const response = await axios.get(
        `https://api.themoviedb.org/3/movie/${submission.movie.id}/watch/providers`,
        {
          params: { api_key: apiKey }
        }
      );
      const providers = response.data.results.US || response.data.results.GB || null;

      await setDoc(doc(db, 'parties', partyCode), {
        winner: {
          movie: submission.movie,
          submitter: submission.name,
          watchProviders: providers
        }
      }, { merge: true });

      navigate('/winner', {
        state: {
          movie: submission.movie,
          submitter: submission.name,
          partyCode: partyCode,
          watchProviders: providers
        },
        replace: true
      });

      // Track party completion
      logAnalyticsEvent('party_completed', {
        party_id: partyCode,
      });
    } catch (error) {
      console.error('Error selecting winner:', error);
    }
  };

  return (
    <div className="submitted-movies-container">
      <div className="header-section">
        <h1>Judge {participantName}</h1>
        <p>Select a movie to watch with your group</p>
      </div>
      
      <div className="submissions-list">
        {submissions.map((submission, index) => (
          <div key={index} className="submission-card">
            <div className="submission-content">
              <img 
                src={submission.movie.poster_path 
                  ? `https://image.tmdb.org/t/p/w200${submission.movie.poster_path}` 
                  : '/na.png'
                } 
                alt={submission.movie.title}
                className="movie-poster"
              />
              <div className="movie-info">
                <h3>
                  {submission.movie.title} ({submission.movie.release_date?.split('-')[0] || 'N/A'})
                </h3>
                <p className="genres">
                  {submission.movie.genres.map(genre => genre.name).join(', ')}
                </p>
                <p className="overview">{submission.movie.overview}</p>
                <button 
                  className="select-button"
                  onClick={() => handleSelectMovie(submission)}
                >
                  Choose Movie
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .submitted-movies-container {
          padding: 20px;
          max-width: 1200px;
          margin: 0 auto;
        }

        .submissions-list {
          display: grid;
          gap: 20px;
          margin-top: 20px;
        }

        .submission-card {
          background: #ffffff;
          border-radius: 8px;
          padding: 15px;
          box-shadow: 0 2px 5px rgba(0,0,0,0.3);
          border: 1px solid #eeeeee;
        }

        .submission-content {
          display: flex;
          gap: 20px;
        }

        .movie-poster {
          width: 130px;
          height: 195px;
          border-radius: 4px;
          object-fit: cover;
          box-shadow: 0 2px 5px rgba(0,0,0,0.3);
        }

        .movie-info {
          flex: 1;
          position: relative;
          font-size: 18px;
        }

        .movie-info h3 {
          margin: 0 0 5px 0;
          color: #333333;
          margin-bottom: 0px;
        }

        .release-date {
          color: #888;
          margin: 5px 0;
        }

        .genres {
          color: #FF4040;
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 14px;
        }

        .overview {
          color: #666666;
          font-size: 12px;
          line-height: 1.4;
          margin-bottom: 50px;
          margin-top: 0px;
        }

        .select-button {
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 10px 20px;
          background-color: #FF4040;
          color: white;
          border: none;
          border-radius: 30px;
          cursor: pointer;
          font-size: 0.9em;
          transition: background-color 0.2s;
          box-shadow: 0 2px 5px rgba(0,0,0,0.3);

        }

        .select-button:hover {
          background-color: #E63737;
        }
      `}</style>
    </div>
  );
}

export default Judge;
