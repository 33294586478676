// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDkG6K0g_MU4abTouwgtbpLBwOoocTHDhM",
  authDomain: "watchparty-7443c.firebaseapp.com",
  projectId: "watchparty-7443c",
  storageBucket: "watchparty-7443c.appspot.com",
  messagingSenderId: "804750354325",
  appId: "1:804750354325:web:617d3b45d7d0627df2f89c",
  measurementId: "G-GCX13MMXC0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

// Helper function for logging events
export const logAnalyticsEvent = (eventName, params = {}) => {
  try {
    logEvent(analytics, eventName, {
      ...params,
      measurement_id: 'G-GCX13MMXC0'
    });
  } catch (error) {
    console.error('Analytics error:', error);
  }
};

export { db, analytics };
