import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { uniqueNamesGenerator, adjectives, animals } from 'unique-names-generator';
import { logAnalyticsEvent } from '../firebase';

function Host() {
  const [partyId, setPartyId] = useState('');
  const [hostName, setHostName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const generateSessionId = () => {
    return uniqueNamesGenerator({
      dictionaries: [adjectives, animals],
      separator: '-',
      length: 2,
    });
  };

  const createParty = async () => {
    setIsLoading(true);
    setError(null);

    const newPartyId = generateSessionId();
    const timestamp = new Date().toISOString();

    try {
      await setDoc(doc(db, 'parties', newPartyId), {
        party_id: newPartyId,
        host_name: hostName,
        created_at: timestamp,
        status: 'created',
      });

      logAnalyticsEvent('party_created', {
        party_id: newPartyId,
        host_name: hostName,
        timestamp: timestamp,
        device_type: window.innerWidth <= 768 ? 'mobile' : 'desktop',
        screen_resolution: `${window.innerWidth}x${window.innerHeight}`,
        user_agent: navigator.userAgent,
        referrer: document.referrer || 'direct'
      });

      console.log('Document written with ID: ', newPartyId);
      setPartyId(newPartyId);
      setIsLoading(false);

      navigate('/role-selection', { state: { participantName: hostName, partyCode: newPartyId } });
    } catch (err) {
      console.error('Error creating watch party:', err);
      setError('Failed to create watch party. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <div className="home-container">
      <h2>New party</h2>

      {!partyId ? (
        <div className="input-container">
          <input
            type="text"
            value={hostName}
            onChange={(e) => setHostName(e.target.value)}
            placeholder="Enter your name"
            className="input-field"
          />
          <button 
            onClick={createParty} 
            disabled={isLoading}
            className="action-button"
          >
            {isLoading ? 'Creating Party...' : 'Create Party'}
          </button>
        </div>
      ) : (
        <div className="party-info">
          <h3>Your Watch Party ID</h3>
          <p>{partyId}</p>
          <p>Share this ID with your friends to join the party!</p>
        </div>
      )}

      {error && <p className="error-message">{error}</p>}

      <style jsx>{`
        .input-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          max-width: 300px;
        }

        .party-info {
          text-align: center;
          margin-top: 20px;
        }

        .error-message {
          color: red;
          text-align: center;
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
}

export default Host;
