import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Winner() {
  const location = useLocation();
  const navigate = useNavigate();
  const { movie, submitter, partyCode, watchProviders } = location.state || {};
  const [showOverlay, setShowOverlay] = useState(true);
  const [showTerminalButton, setShowTerminalButton] = useState(false);

  useEffect(() => {
    const overlayTimer = setTimeout(() => {
      setShowOverlay(false);
    }, 10000);

    const buttonTimer = setTimeout(() => {
      setShowTerminalButton(true);
    }, 12000);

    return () => {
      clearTimeout(overlayTimer);
      clearTimeout(buttonTimer);
    };
  }, []);

  if (!movie) {
    navigate('/');
    return null;
  }

  const renderWatchProviders = () => {
    if (!watchProviders) return null;

    // Combine rent and buy providers
    const rentBuyProviders = new Map();
    ['rent', 'buy'].forEach(type => {
      if (watchProviders[type]) {
        watchProviders[type].forEach(provider => {
          rentBuyProviders.set(provider.provider_id, provider);
        });
      }
    });

    // Get streaming providers
    const streamProviders = new Map();
    if (watchProviders.flatrate) {
      watchProviders.flatrate.forEach(provider => {
        streamProviders.set(provider.provider_id, provider);
      });
    }

    if (streamProviders.size === 0 && rentBuyProviders.size === 0) return null;

    return (
      <div className="watch-providers-overlay">
        {streamProviders.size > 0 && (
          <div className="provider-row">
            <span>Stream:</span>
            <div className="provider-logos">
              {Array.from(streamProviders.values()).map(provider => (
                <img
                  key={provider.provider_id}
                  src={`https://image.tmdb.org/t/p/original${provider.logo_path}`}
                  alt={provider.provider_name}
                  title={provider.provider_name}
                  className="provider-logo"
                />
              ))}
            </div>
          </div>
        )}
        {rentBuyProviders.size > 0 && (
          <div className="provider-row">
            <span>Rent/Buy:</span>
            <div className="provider-logos">
              {Array.from(rentBuyProviders.values()).map(provider => (
                <img
                  key={provider.provider_id}
                  src={`https://image.tmdb.org/t/p/original${provider.logo_path}`}
                  alt={provider.provider_name}
                  title={provider.provider_name}
                  className="provider-logo"
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="home-container">
      {showOverlay && (
        <div className="winner-overlay">
          <h1>Winner Selected!</h1>
          <iframe 
            src="https://giphy.com/embed/A8OSeenhtpey43CcMG" 
            width="100%" 
            height="274" 
            frameBorder="0" 
            className="giphy-embed" 
            allowFullScreen
            title="Winner Celebration GIF"
          />
        </div>
      )}
      <div className="winner-card">
        <div className="poster-container">
          <img 
            src={movie.poster_path 
              ? `https://image.tmdb.org/t/p/w400${movie.poster_path}` 
              : '/na.png'
            } 
            alt={movie.title}
            className="movie-poster"
          />
          <div className="overlay">
            <div className="movie-details">
              <h2>{movie.title}</h2>
              <p className="year">({movie.release_date?.split('-')[0] || 'N/A'})</p>
              <p className="submitter">Submitted by {submitter}</p>
            </div>
            {renderWatchProviders()}
            {showTerminalButton && (
              <button 
                className="terminal-button"
                onClick={() => navigate('/terminal', {
                  state: { partyCode }
                })}
              >
                ➜
              </button>
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        .winner-card {
          width: 100%;
          height: 77vh;
          margin: 0;
          margin-top: 5px;
          position: fixed;
          top: 80px;
          left: 50%;
          transform: translateX(-50%);
          max-width: 480px;
          border-radius: 10px;
          overflow: hidden;
        }

        .poster-container {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }

        .movie-poster {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          border-radius: 25px;
          box-shadow: 0 2px 10px rgba(0,0,0,1);
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
          background: linear-gradient(to bottom, 
            rgba(0,0,0,0.9) 0%,
            rgba(0,0,0,0.5) 20%,
            rgba(0,0,0,0.3) 50%,
            rgba(0,0,0,0.7) 80%,
            rgba(0,0,0,0.9) 100%
          );
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-radius: 25px;
          box-sizing: border-box;
        }

        .movie-details {
          color: white;
        }

        .movie-details h2 {
          margin: 0;
          font-size: 24px;
          text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
        }

        .year {
          color: #ddd;
          margin: 5px 0 5px 0;
          font-size: 18px;
          text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
        }

        .submitter {
          color: #ffffff;
          font-weight: semibold;
          font-size: 20px;
          margin: 0px 0;
          text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
        }

        .watch-providers-overlay {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .provider-row {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .provider-row span {
          color: #ddd;
          font-size: 14px;
          text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
          white-space: nowrap;
        }

        .provider-logos {
          display: flex;
          gap: 8px;
          flex-wrap: wrap;
          align-items: center;
        }

        .provider-logo {
          width: 30px;
          height: 30px;
          border-radius: 6px;
          object-fit: cover;
          transition: transform 0.2s;
          box-shadow: 0 2px 4px rgba(0,0,0,0.3);
        }

        .provider-logo:hover {
          transform: scale(1.1);
        }

        .winner-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #1b1a1a;
          z-index: 2000;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
          animation: fadeOut 2s ease 10s forwards;
        }

        .winner-overlay h1 {
          color: #ffffff;
          margin-bottom: 30px;
          font-size: 32px;
          text-align: center;
          animation: pulse 2s infinite;
        }

        .giphy-embed {
          max-width: 480px;
          width: 100%;
          border-radius: 8px;
        }

        @keyframes fadeOut {
          from { opacity: 1; }
          to { opacity: 0; visibility: hidden; }
        }

        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.1); }
          100% { transform: scale(1); }
        }

        .terminal-button {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          background-color: transparent;
          border: 3px solid #ffffff;
          color: #ffffff;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          cursor: pointer;
          transition: all 0.3s ease;
          z-index: 1002;
        }

        .terminal-button:hover {
          background-color: #FF4040;
          border-color: #FF4040;
          color: white;
          transform: translateY(-50%) scale(1.1);
        }
      `}</style>
    </div>
  );
}

export default Winner;