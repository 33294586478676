import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Home from './components/Home';
import Host from './components/Host';
import RoleSelection from './components/RoleSelection';
import Participant from './components/Participant';
import Judge from './components/Judge';
import LinkInvitation from './components/LinkInvitation';
import SubmittedMovies from './components/SubmittedMovies';
import Winner from './components/Winner';
import Terminal from './components/Terminal';
import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';
import { logAnalyticsEvent } from './firebase';

// Create a new NavBar component
function NavBar({ isHome }) {
  const navigate = useNavigate();
  
  return (
    <nav className={`navbar ${isHome ? 'home-page' : ''}`}>
      <button className="nav-logo-button" onClick={() => navigate('/')}>
        <img src={logo} alt="Home" className="nav-logo" />
      </button>
    </nav>
  );
}

// Create AppContent component to use Router hooks
function AppContent() {
  const location = useLocation();
  const isHome = location.pathname === '/';

  useEffect(() => {
    logAnalyticsEvent('page_view', {
      page_title: 'home',
      page_location: window.location.href,
    });
  }, []);

  return (
    <div className="App">
      <NavBar isHome={isHome} />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/host" element={<Host />} />
          <Route path="/role-selection" element={<RoleSelection />} />
          <Route path="/participant" element={<Participant />} />
          <Route path="/judge" element={<Judge />} />
          <Route path="/link-invitation" element={<LinkInvitation />} />
          <Route path="/submitted-movies" element={<SubmittedMovies />} />
          <Route path="/winner" element={<Winner />} />
          <Route path="/terminal" element={<Terminal />} />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
