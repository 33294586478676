import React, { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { collection, addDoc, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import naImage from './na.png';
import { useLocation, useNavigate } from 'react-router-dom';
import ShareButton from './ShareButton';

function Participant() {
  const location = useLocation();
  const navigate = useNavigate();
  const { participantName, partyCode } = location.state || {};
  const [query, setQuery] = useState('');
  const [movies, setMovies] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [genres, setGenres] = useState({});
  const [shareableLink, setShareableLink] = useState('');
  const [judgeName, setJudgeName] = useState(null);

  const apiKey = '761fc976d86696491aa70fe1e99e7b9b';
  const accessToken = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI3NjFmYzk3NmQ4NjY5NjQ5MWFhNzBmZTFlOTllN2I5YiIsIm5iZiI6MTcyOTY0Mzg1Mi4wMjczMzQsInN1YiI6IjY2YTgwNTUxMzBkMWNkZGVhMGIzMWEyOSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.QCBcwRpgMAULZWyg0_gyFauP5cLcTeQKUq9IxGCRxg4';

  const fetchGenres = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.themoviedb.org/3/genre/movie/list`, {
        params: {
          api_key: apiKey,
        },
        headers: {
          Authorization: accessToken
        }
      });
      const genreMapping = {};
      response.data.genres.forEach(genre => {
        genreMapping[genre.id] = genre.name;
      });
      setGenres(genreMapping);
    } catch (error) {
      console.error('Error fetching genres from TMDB', error);
    }
  }, [apiKey, accessToken]);

  useEffect(() => {
    fetchGenres();
  }, [fetchGenres]);

  const fetchMovies = useCallback(async (searchQuery) => {
    try {
      const response = await axios.get(
        `https://api.themoviedb.org/3/search/movie`,
        {
          params: {
            api_key: apiKey,
            query: searchQuery
          },
          headers: {
            Authorization: accessToken
          }
        }
      );
      setMovies(response.data.results);
    } catch (error) {
      console.error('Error fetching data from TMDB', error);
    }
  }, [apiKey, accessToken]);

  const debouncedFetchMovies = useMemo(
    () => debounce((searchQuery) => fetchMovies(searchQuery), 500),
    [fetchMovies]
  );

  useEffect(() => {
    return () => {
      debouncedFetchMovies.cancel();
    };
  }, [debouncedFetchMovies]);

  const handleInputChange = (e) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);

    if (searchQuery.length > 2) {
      debouncedFetchMovies(searchQuery);
    } else {
      debouncedFetchMovies.cancel();
      setMovies([]);
    }
  };

  const handleMovieSelect = (movie) => {
    setQuery(movie.title);
    setSelectedMovie(movie);
    setMovies([]);
  };

  const handleSubmit = async () => {
    if (!selectedMovie || !participantName) {
      alert('Please select a movie and ensure your name is provided.');
      return;
    }

    try {
      const submissionsRef = collection(db, 'parties', partyCode, 'submissions');

      await addDoc(submissionsRef, {
        movieId: selectedMovie.id,
        name: participantName,
      });

      navigate('/submitted-movies', {
        state: {
          participantName,
          partyCode
        },
        replace: true
      });
    } catch (error) {
      console.error('Error submitting data to Firestore:', error);
      alert('Error submitting your selection. Please try again.');
    }
  };

  useEffect(() => {
    const generateShareableLink = () => {
      const baseUrl = window.location.origin;
      const link = `${baseUrl}/link-invitation?partyCode=${partyCode}`;
      setShareableLink(link);
    };

    generateShareableLink();
  }, [partyCode]);

  useEffect(() => {
    if (!partyCode) return;

    const unsubscribe = onSnapshot(doc(db, 'parties', partyCode), (doc) => {
      const data = doc.data();
      if (data?.judge_name) {
        setJudgeName(data.judge_name);
      }
    });

    return () => unsubscribe();
  }, [partyCode]);

  console.log('Participant Component Location State:', location.state);

  return (
    <div className="home-container scroll-container">
      <div className="header-section">
        <h1>Hi, {participantName}!</h1> 
        <p>Search for a movie to submit to the judge{judgeName ? `, ${judgeName}` : ''}</p>
      </div>

      <div className="search-container">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search for a movie..."
          className="input-field"
        />
        {movies.length > 0 && (
          <ul className="movie-dropdown">
            {movies.map((movie) => (
              <li key={movie.id} onClick={() => handleMovieSelect(movie)} className="movieItem">
                <img 
                  src={movie.poster_path ? `https://image.tmdb.org/t/p/w200${movie.poster_path}` : naImage} 
                  alt={movie.title} 
                  className="moviePoster" 
                />
                <div className="movieDetails">
                  <div className="movieTitle">
                    {movie.title} ({movie.release_date ? movie.release_date.split('-')[0] : 'N/A'})
                  </div>
                  <div className="movieGenre">
                    {movie.genre_ids.map(id => genres[id] || id).join(', ')}
                  </div>
                  <div className="movieDescription">
                    {movie.overview || 'No description available.'}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="button-container">
        <button onClick={handleSubmit} className="action-button">
          Submit Movie
        </button>
      </div>

      <ShareButton shareableLink={shareableLink} partyCode={partyCode} />

      <style jsx>{`
        .header-section {
          margin-bottom: 10px;
          text-align: center;
        }

        .header-section h1 {
          margin-bottom: 5px;
          margin-top: 0px;
        }

        .header-section p {
          margin: 0;
        }

        .search-container {
          position: relative;
          width: 100%;
          margin: 0;
          overflow: visible;
        }

        .movie-dropdown {
          list-style-type: none;
          padding: 0;
          margin: 0;
          border: 1px solid #cccccc;
          position: absolute;
          width: 100%;
          max-height: 60vh;
          overflow-y: auto;
          border-radius: 5px;
          z-index: 1001;
          background-color: #ffffff;
          box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        }

        .movie-dropdown::-webkit-scrollbar {
          width: 8px;
        }

        .movie-dropdown::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 4px;
        }

        .movie-dropdown::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        .movie-dropdown::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .movieItem {
         background-color: #ffffff;
          padding: 15px;
          cursor: pointer;
          display: flex;
          border-bottom: 1px solid #eeeeee;
          overflow: visible;
        }

        .movieItem:hover {
          background-color: #eeeeee;
          overflow: visible;
        }

        .moviePoster {
          width: 130px;
          height: 195px;
          border-radius: 4px;
          margin-right: 15px;
          object-fit: cover;
          box-shadow: 0 2px 5px rgba(0,0,0,0.3);
        }

        .movieDetails {
          flex: 1;
        }

        .movieTitle {
          font-size: 18px;
          color: black;
          margin-bottom: 0px;
          font-weight: bold;
        }

        .movieGenre {
          color: #FF4040;
          margin-bottom: 0px;
          font-size: 14px;
        }

        .movieDescription {
          color: #666666;
          font-size: 12px;
          line-height: 1.4;
        }

        .search-container input {
          width: 100%;
          box-sizing: border-box;
          margin: 0;
        }
      `}</style>
    </div>
  );
}

export default Participant;
