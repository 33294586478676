// src/components/LinkInvitation.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import ShareButton from './ShareButton';

function LinkInvitation() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [participantName, setParticipantName] = useState('');
  const [error, setError] = useState('');
  const [shareableLink, setShareableLink] = useState('');
  const partyCode = searchParams.get('partyCode');

  useEffect(() => {
    if (partyCode) {
      const baseUrl = window.location.origin;
      const link = `${baseUrl}/link-invitation?partyCode=${partyCode}`;
      setShareableLink(link);
    }
  }, [partyCode]);

  const handleJoinParty = async () => {
    if (!participantName.trim()) {
      setError('Please enter your name');
      return;
    }

    const partyRef = collection(db, 'parties');
    const q = query(partyRef, where('party_id', '==', partyCode));

    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        navigate('/role-selection', { 
          state: { 
            participantName: participantName.trim(), 
            partyCode 
          } 
        });
      } else {
        setError('Invalid party code');
      }
    } catch (err) {
      console.error('Error checking party code:', err);
      setError('Error joining party');
    }
  };

  if (!partyCode) {
    return (
      <div className="home-container">
        <div className="error-message">Invalid invitation link</div>
      </div>
    );
  }

  return (
    <div className="home-container">
      <div className="header-section">
        <h2>Join Watch Party</h2>
        <p>Enter your name to join the session</p>
      </div>
      
      <div className="input-container">
        <input
          type="text"
          value={participantName}
          onChange={(e) => setParticipantName(e.target.value)}
          placeholder="Enter your name"
          className="input-field"
        />
        <button onClick={handleJoinParty} className="action-button">
          Join Party
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}

      <ShareButton shareableLink={shareableLink} partyCode={partyCode} />

      <style jsx>{`
        .input-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          margin: 20px 0;
        }

        .error-message {
          color: red;
          text-align: center;
          margin-top: 10px;
          font-size: 14px;
        }
      `}</style>
    </div>
  );
}

export default LinkInvitation;
