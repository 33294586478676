import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import logo from '../logo.svg';

function Home() {
  const navigate = useNavigate();
  const [isJoining, setIsJoining] = useState(false);
  const [partyCode, setPartyCode] = useState('');
  const [participantName, setParticipantName] = useState('');
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerColor, setBannerColor] = useState('');
  const [showCurtain, setShowCurtain] = useState(true);
  const [showTopCurtain, setShowTopCurtain] = useState(true);
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowCurtain(false);
      setShowLogo(false);
      setTimeout(() => {
        setShowTopCurtain(false);
      }, 1000);
    }, 200);
  }, []);

  const startParty = () => {
    navigate('/host');
  };

  const joinParty = () => {
    setIsJoining(true);
  };

  const handlePartyCodeSubmit = async () => {
    setBannerMessage('');
    const partyRef = collection(db, 'parties');
    const q = query(partyRef, where('party_id', '==', partyCode));

    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setBannerMessage('Success!');
        setBannerColor('green');
        navigate('/role-selection', { state: { participantName, partyCode } });
      } else {
        setBannerMessage('Party code invalid.');
        setBannerColor('red');
      }
    } catch (error) {
      console.error('Error checking party code:', error);
      setBannerMessage('Error checking party code.');
      setBannerColor('red');
    }

    setPartyCode('');
    setParticipantName('');
  };

  return (
    <div className="home-container">
      <div className={`curtain ${showCurtain ? '' : 'open'}`}>
        <div className="curtain-left"></div>
        <div className="curtain-right"></div>
      </div>
      <div className={`curtain-top ${showTopCurtain ? '' : 'open'}`}></div>
      
      <img 
        src={logo} 
        alt="Movie Party" 
        className={`center-logo ${showLogo ? '' : 'shrink'}`}
      />

      <h1>Welcome to Movie Party</h1>
      {bannerMessage && (
        <div className="banner" style={{ backgroundColor: bannerColor }}>
          {bannerMessage}
        </div>
      )}
      <div className="button-container">
        {!isJoining ? (
          <>
            <button onClick={startParty} className="action-button">Start a Party</button>
            <button onClick={joinParty} className="action-button-secondary">Join a Party</button>
          </>
        ) : (
          <div className="join-party-container">
            <input
              type="text"
              value={participantName}
              onChange={(e) => setParticipantName(e.target.value)}
              placeholder="Your Name"
              className="input-field"
            />
            <input
              type="text"
              value={partyCode}
              onChange={(e) => setPartyCode(e.target.value)}
              placeholder="Enter Party Code"
              className="input-field"
            />
            <button onClick={handlePartyCodeSubmit} className="action-button">Submit Party Code</button>
          </div>
        )}
      </div>
      
      <div className="instructions">
        <h2 style={{ textAlign: 'center' }}>The best way to watch movies with friends</h2>
        <p>1. <strong>Start a Party:</strong> Get some friends together and start a party</p>
        <p>2. <strong>Join a Party:</strong> Share the invite link or party code with the group</p>
        <p>3. <strong>Choose a Judge:</strong> Decide who will be the judge</p>
        <p>4. <strong>Submit Movies:</strong> Everyone submits one movie anonymously for the judge</p>
        <p>5. <strong>Selection:</strong> Judges can view all submitted movies and make selections.</p>
      </div>

      <style jsx>{`
        .instructions {
          margin-top: 30px;
          width: 90%;
          max-width: 600px;
          padding: 15px;
          border-radius: 10px;
          font-size: 14px;
          line-height: 1.4;
          text-align: left;
          border: 2px solid #333;
        }

        .instructions p {
          margin: 5px 0;
        }

        .instructions strong {
          color: #FF4040;
        }

        .join-party-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }

        .curtain {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2000;
          pointer-events: none;
        }

        .curtain-left,
        .curtain-right {
          position: absolute;
          width: 50%;
          height: 100%;
          background: #FF4040;
          transition: transform 2s cubic-bezier(0.86, 0, 0.07, 1);
          background-image: repeating-linear-gradient(
            to right,
            rgba(0, 0, 0, 0.1) 0px,
            rgba(0, 0, 0, 0.1) 10px,
            transparent 10px,
            transparent 40px
          );
          box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.3);
        }

        .curtain-left {
          left: 0;
          transform-origin: left;
          border-right: 10px solid rgba(0, 0, 0, 0.2);
        }

        .curtain-right {
          right: 0;
          transform-origin: right;
          border-left: 10px solid rgba(0, 0, 0, 0.2);
        }

        .curtain.open .curtain-left {
          transform: translateX(-100%);
        }

        .curtain.open .curtain-right {
          transform: translateX(100%);
        }

        .curtain-top {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 10vh;
          background: #FF4040;
          z-index: 2004;
          transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
          transform-origin: top;
          background-image: repeating-linear-gradient(
            to right,
            rgba(0, 0, 0, 0.1) 0px,
            rgba(0, 0, 0, 0.1) 10px,
            transparent 10px,
            transparent 40px
          );
          box-shadow: inset 0 -20px 50px rgba(0, 0, 0, 0.3);
          border-bottom: 10px solid rgba(0, 0, 0, 0.2);
        }

        .curtain-top.open {
          transform: translateY(-100%);
        }

        .center-logo {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 300px;
          height: 300px;
          z-index: 2002;
          transition: all 1.3s cubic-bezier(0.86, 0, 0.07, 1) 0.5s;
        }

        .center-logo.shrink {
          width: 60px;
          height: 60px;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
        }
      `}</style>
    </div>
  );
}

export default Home;
