import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useLocation } from 'react-router-dom';
import { logAnalyticsEvent } from '../firebase';

function Terminal() {
  const location = useLocation();
  const { partyCode } = location.state || {};
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [subscribeStatus, setSubscribeStatus] = useState('');
  const [feedbackStatus, setFeedbackStatus] = useState('');

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    setSubscribeStatus('');
    try {
      await addDoc(collection(db, 'emails'), {
        email: email.trim(),
        partyCode: partyCode || 'Direct',
        timestamp: new Date().toISOString()
      });
      logAnalyticsEvent('email_subscription', { party_code: partyCode });
      setEmail('');
      setSubscribeStatus('✓ Subscribed!');
      setTimeout(() => setSubscribeStatus(''), 2000);
    } catch (error) {
      setSubscribeStatus('Failed to subscribe');
    }
  };

  const handleSubmitFeedback = async (e) => {
    e.preventDefault();
    setFeedbackStatus('');
    try {
      await addDoc(collection(db, 'feedback'), {
        feedback: feedback.trim(),
        partyCode: partyCode || 'Direct',
        timestamp: new Date().toISOString()
      });
      logAnalyticsEvent('feedback_submitted', { party_code: partyCode });
      setFeedback('');
      setFeedbackStatus('✓ Submitted!');
      setTimeout(() => setFeedbackStatus(''), 2000);
    } catch (error) {
      setFeedbackStatus('Failed to submit');
    }
  };

  return (
    <div className="terminal-page">

        <div className="section-card">
          <div className="card-content">
            <h2>Support Movie Party</h2>
            <p>Help keep Movie Party free and ad-free</p>
          </div>
          <button 
            onClick={() => window.open('https://donate.stripe.com/dR6bLlfkqgIg7vy000', '_blank')}
            className="card-button"
          >
            ☕️ Buy me a coffee
          </button>
        </div>



        <div className="section-card">
          <div className="card-content">
            <h2>Stay Updated</h2>
            <p>Get notified about new features</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          <button 
            onClick={handleSubmitEmail}
            className="card-button"
          >
            {subscribeStatus || 'Subscribe'}
          </button>
        </div>



        <div className="section-card">
          <div className="card-content">
            <h2>Give Feedback</h2>
            <p>Help us improve Movie Party</p>
            <input
              type="text"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Share your thoughts..."
              required
            />
          </div>
          <button 
            onClick={handleSubmitFeedback}
            className="card-button"
          >
            {feedbackStatus || 'Submit'}
          </button>
        </div>


      <style jsx>{`
        .terminal-page {
          height: calc(100vh - 100px);
          display: grid;
          grid-template-rows: auto auto auto;
          gap: 10px;
          padding: 10px;
        }

        .section-card {
          background: white;
          border-radius: 12px;
          padding: 15px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.4);
          display: flex;
          flex-direction: column;
          grid-template-rows: auto auto auto;
        }


        .card-content h2 {
          margin-top: 0;
          margin-bottom: 5px; /* Example value */
          font-size: 18px;
          font-weight: 600;
        }

        .card-content p {
          margin-top: 0;
          margin-bottom: 05px;
          font-size: 14px;
        }

        .card-button {
          width: 100%;
          padding: 12px;
          border-radius: 20px;
          font-size: 14px;
          font-weight: bold;
          background: #FF4040;
          color: white;
          border: none;
          margin-top: 10px;
          font-family: inherit;
        }
      `}</style>
    </div>
  );
}

export default Terminal; 